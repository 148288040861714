<template>

  <nav class="navbar bg-body-light sticky-top " style="background-color: white;">
    <div class="container-fluid d-flex justify-content-evenly align-items-center" id="header">
      <a class="navbar-brand" href="#"><img src="./assets/media/logo.svg" alt=""></a>

      <div class="" id="big-nav">
        <ul class="d-flex justify-content-between align-items-center">

          <!-- Nav item -->
          <li class="nav-item d-flex justify-content-center align-items-end">
            <a  class="nav-link px-3 py-1" aria-current="page"
              @click="currentPage = 'home'" href="">Home</a>
          </li>
          <li class="nav-item">
            <a :class="nav" class="nav-link  px-3 py-1" aria-current="page"
              @click="currentPage = 'about',color = true" href="#">About</a>
          </li>
          <li class="nav-item">
            <a  :class="nav" class="nav-link  px-3 py-1" aria-current="page"
              @click="currentPage = 'contact',color = true" href="#">Contact</a>
          </li>
          <li class="nav-item">
            <a :class="nav" class="nav-link px-3 py-1" aria-current="page"
              @click="currentPage = 'serv',color = true" href="#">Services</a>
          </li>

        </ul>
      </div>
      <div>
        <button type="button" class="btn btn-secondary sign">Sign Up</button>
        <button type="button" class="btn btn-success sign mx-2">Buy!</button>
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">Mizzle</h5>
          <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item">
              <a class="nav-link"  @click="currentPage = 'home'" aria-current="page" href="">Home</a>
              <a class="nav-link"  @click="currentPage = 'about'" aria-current="page" href="#">About</a>
              <a class="nav-link"  @click="currentPage = 'contact'" aria-current="page" href="#">Contact</a>
              <a class="nav-link"  @click="currentPage = 'serv'" aria-current="page" href="#">Services</a>
            </li>
           
           
          </ul>
          
        </div>
      </div>
    </div>
  </nav>

  <div v-if="currentPage === 'home'">
    <HomeM msg="Welcome to Your Vue.js App" />
  </div>
  <div v-if="currentPage === 'contact'">
    <ContactM msg="Welcome to Your Vue.js App" />
  </div>
  <div v-if="currentPage === 'serv'">
    <ServM msg="Welcome to Your Vue.js App" />
  </div>
  <div v-if="currentPage === 'about'">
    <AboutM msg="Welcome to Your Vue.js App" />
  </div>







  <footer class="bg-dark position-relative overflow-hidden pb-0 pt-6 pt-lg-8" data-bs-theme="dark">

    <!-- SVG decoration -->
    <figure class="position-absolute top-0 start-0 mt-n8 ms-n9">
      <svg class="fill-mode" width="775px" height="834px" viewBox="0 0 775 834"
        style="enable-background:new 0 0 775 834; opacity: 0.05;" xml:space="preserve">
        <path
          d="M486.1,564.4c-3.6,2.5-7.4,4.8-11.3,6.4c-12,5.5-25.7,7.9-42.2,7.4c-30.6-1.1-65.6-12.5-102.8-24.4 c-50.7-16.2-103.3-33.4-152.5-27c-56.1,7.2-97.9,44.4-128,114l-0.4-0.2c67.5-156.1,181-119.5,281.1-87.1c37,12,72,23.2,102.5,24.3 c34.3,1.2,58.1-10.7,74.9-37.4C530.1,505,547.1,466,565,425.1C619.4,301,675.6,172.7,892.1,141.3l0.1,0.4 c-216.2,31.4-272.5,159.5-326.8,283.5c-18.1,41.1-35,79.7-57.7,115.6C501.6,550.7,494.5,558.5,486.1,564.4z">
        </path>
        <path
          d="M500.9,551.4c-43.7,31-103,15.8-165.5-0.2c-49.9-12.7-101.5-25.8-148.7-16.7c-53.3,10.5-93.2,49-121.6,118 l-0.5-0.1c15.3-37.1,33.3-64.7,55.1-84.7c19.5-17.7,41.3-28.6,66.7-33.7c47.4-9.2,99,3.9,148.9,16.6 c70.4,17.9,137.1,34.9,181.3-14.4c35.7-39.9,57.3-91.7,80.2-146.7c23.8-56.7,48.2-115.5,90.2-163.6c22.7-25.9,48.4-46.4,78.4-62.4 c33.9-18.1,72.2-30.3,117.1-37.1l0.1,0.4C695,155.3,645.2,274.5,597.1,389.7c-22.9,55-44.5,106.8-80.4,146.8 C512.3,542.4,506.6,547.3,500.9,551.4z">
        </path>
        <path
          d="M521.3,536.4c-21.9,15.5-48.4,23.4-80.8,23.8c-31.2,0.5-65.1-5.8-97.9-11.9c-49.3-9.2-100.2-18.7-145.7-6.5 c-51.1,13.7-88.9,53.7-116,122.6l-0.6-0.2c60.5-154.1,163.3-135,262.6-116.5c68.1,12.7,132.6,24.6,183.6-15.8 c48.1-38.2,71.1-100.6,95.6-166.5c20.3-55,41.4-111.6,78.3-158.1c20-25.1,42.7-44.9,69.2-60.5c30.1-17.5,64.2-29.1,104.3-35.4 l0.2,0.6c-167.2,26.3-210,141.9-251.4,253.5C598.3,431.5,575,493.8,527,532.2C525.1,533.8,523.2,535.1,521.3,536.4z">
        </path>
        <path
          d="M548.9,520.3c-4,2.9-8.2,5.6-12.6,8c-56.6,31.5-120.9,23.8-183,16.6c-51.7-6-100.4-11.8-144.6,3.2 c-49.9,16.9-85.5,57.7-111.3,128.2l-0.6-0.2c13.7-37.3,30.1-66,49.9-87.8c17.8-19.4,37.9-32.8,61.8-40.9 c44.3-15,93.1-9.3,144.9-3.2c62.1,7.2,126.3,14.8,182.8-16.6c59.6-33.2,82-104.7,105.9-180.4c17.1-54.3,34.7-110.5,67.2-156.6 c36.7-52,87.8-82.8,155.7-94l0.2,0.6c-151.9,25-187.8,139.3-222.3,250C620.4,417.6,599.4,484.5,548.9,520.3z">
        </path>
        <path
          d="M573.5,509.5c-8.2,5.8-17.4,10.7-27.7,14.6c-59.3,22-119.1,18.8-176.8,15.8c-53.2-2.8-103.3-5.3-147.1,12.5 C172.6,572.3,138.1,615.5,113,688l-0.5-0.1c25.1-72.7,59.6-115.9,108.9-136c44-18,94.2-15.3,147.6-12.6 c57.7,3,117.4,6.1,176.6-15.9c70.7-26.2,91.1-106.3,112.8-191.4c13.9-54.5,28.3-111,56.7-156.9C747,123.2,793,92.6,855.6,82l0,0.7 C716.3,106.5,687,221.4,658.9,332.2C640.4,405,622.6,474.4,573.5,509.5z">
        </path>
        <path
          d="M595.2,502.3c-11.3,8-24.6,14-40,17.4c-56.8,12.7-112,12.7-160.5,12.9c-60.2,0.1-112,0.2-157,21.1 c-49.5,23-84,69.3-108.5,146l-0.6-0.2c24.3-76.7,58.9-123.1,108.6-146.3c45.1-21.1,97.2-21.1,157.4-21.2 c48.6,0,103.6-0.1,160.5-12.9c81.6-18.3,99-106.7,117.4-200.6c10.7-55,22-112,46.6-158.2C747,108,788.6,77.5,846.5,67.2l0.1,0.8 C718,91.2,695.2,206.9,673.2,318.9C658.3,394.9,643.8,467.8,595.2,502.3z">
        </path>
        <path
          d="M615.3,497.4c-13.7,9.7-30.2,16-50.8,18c-44.4,4.6-86.5,5.8-123.6,6.8c-71.2,2-132.8,3.7-182,27.7 C206,575.6,169.8,627,145,711.3l-0.8-0.1c13-44.6,29-79.3,48.6-106.3c18.1-24.9,39.5-43.1,65.6-55.7 c49.5-24.1,110.9-25.8,182.4-27.7c37.1-1,79.3-2.2,123.5-6.7c92.6-9.4,106.2-106.5,120.5-209.2c7.8-55.9,15.9-113.6,37-160 c23.8-52.7,61.6-83.1,115.3-93.4l0.3,0.7c-53.4,10.1-91,40.4-114.6,92.9c-21.1,46.4-29.2,104.1-36.8,159.9 C674.6,386,663.8,463,615.3,497.4z">
        </path>
        <path
          d="M634.4,494c-15.5,11-35.2,17.2-60.4,17.3c-12.3,0.1-24.5,0.1-36.1,0.1c-103.7,0-185.5-0.1-246.4,26.4 c-63.5,27.7-103.7,85-130.5,185.5l-0.8-0.1c13.9-52.5,31.3-92.6,53.2-122.9c20.7-28.8,46.2-49.4,77.8-63.2 c61-26.6,142.9-26.4,246.6-26.4c11.7,0.1,23.8,0,36.1-0.1c103.8-0.2,112.9-105.6,122.5-217.2c4.7-56.9,9.9-115.5,27.5-162.4 c20-53.1,54.1-83.7,104.1-93.7l0.1,0.8c-49.5,9.8-83.5,40.3-103.3,93.1c-17.6,46.9-22.7,105.4-27.6,162 C690.1,378.2,682.9,459.6,634.4,494z">
        </path>
        <path
          d="M652.7,491.8c-17.9,12.7-40.7,17.7-69.2,15.4C328,486.2,228.3,517.5,177.2,735.2l-0.9-0.3 c25.9-110.7,64-171.6,127-204c66.6-34.2,160.2-34.6,280.3-24.7c32.2,2.6,56.9-4.1,75.4-20.5c42.1-37.4,45.1-118.6,48-204.7 c4-116.5,8.1-236.8,112.1-258.6l0.1,0.8C715.9,44.8,711.8,164.8,707.8,280.9c-3.1,86.3-5.8,167.7-48.3,205.2 C657.3,488.3,655,490.1,652.7,491.8z">
        </path>
        <path
          d="M670.6,490.3c-19.3,13.7-44.8,17.9-77.7,12.7c-138.5-21.4-227.1-13-287.3,27 c-55.4,36.8-89.1,101.7-112.4,216.9l-0.9-0.3C215.8,631,249.6,566,305.1,528.9c60.3-40.1,149.1-48.6,288.1-27.3 c35.9,5.5,63,0,82.6-16.9c43.2-37.5,42.2-124.3,40.9-216.1C714.9,151,713,28.8,809.9,7.7l0.1,0.8c-96,21.1-94.3,142.7-92.7,260.6 c1.3,92.1,2.4,179-41.1,216.7C674.3,487.4,672.6,488.9,670.6,490.3z">
        </path>
      </svg>
    </figure>

    <!-- SVG decoration -->
    <div class="position-absolute top-0 end-0 mt-n3 me-n4">
      <img src="assets/images/elements/decoration-pattern-2.svg" style="opacity:0.05;" alt="">
    </div>

    <div class="container position-relative mt-5">
      <div class="row g-4 justify-content-between">

        <!-- Widget 1 START -->
        <div class="col-lg-3">
          <!-- logo -->
          <a class="me-0" href="index.html">
            <img class="light-mode-item h-40px" src="https://mizzle.webestica.com/assets/images/logo.svg" alt="logo">
            <img class="dark-mode-item h-40px" src="https://mizzle.webestica.com/assets/images/logo-light.svg"
              alt="logo">
          </a>

          <p class="mt-4 mb-2">A Bootstrap theme that's both stylish and functional, perfect for any type of technology
            or corporate website.</p>
        </div>
        <!-- Widget 1 END -->

        <!-- Widget 2 START -->
        <div class="col-lg-8 col-xxl-7">
          <div class="row g-4">
            <!-- Link block -->
            <div class="col-6 col-md-4">
              <h6 class="mb-2 mb-md-4">Quick links</h6>
              <ul class="nav flex-column">
                <li class="nav-item"><a class="nav-link pt-0" href="#">About us</a></li>
                <li class="nav-item"><a class="nav-link" href="#">Contact us</a></li>
                <li class="nav-item"><a class="nav-link" href="#">Career <span class="badge text-bg-danger ms-2">2
                      Job</span></a></li>
                <li class="nav-item"><a class="nav-link" href="#">Career detail</a></li>
                <li class="nav-item"><a class="nav-link" href="#">Become a partner</a></li>
                <li class="nav-item"><a class="nav-link" href="#">Sign in</a></li>
                <li class="nav-item"><a class="nav-link" href="#">Sign up</a></li>
              </ul>
            </div>

            <!-- Link block -->
            <div class="col-6 col-md-4">
              <h6 class="mb-2 mb-md-4">Community</h6>
              <ul class="nav flex-column">
                <li class="nav-item"><a class="nav-link pt-0" href="#">Documents</a></li>
                <li class="nav-item"><a class="nav-link" href="#">Supports <i
                      class="bi bi-box-arrow-up-right small ms-1"></i></a></li>
                <li class="nav-item"><a class="nav-link" href="faq.html">Faqs</a></li>
                <li class="nav-item"><a class="nav-link" href="#">Privacy Policy</a></li>
                <li class="nav-item"><a class="nav-link" href="blog-grid.html">News and blogs</a></li>
                <li class="nav-item"><a class="nav-link" href="#">Terms &amp; condition</a></li>
              </ul>
            </div>

            <!-- Link block -->

          </div>
        </div>
        <!-- Widget 2 END -->
      </div>

      <!-- Divider -->
      <hr class="mt-4 mb-0">

      <!-- Bottom footer -->
      <div class="d-md-flex justify-content-between align-items-center text-center text-lg-start py-4">
        <!-- copyright text -->
        <div class="text-body"> Copyrights ©2024 Mizzle. Build by Webestica. </div>


      </div>
    </div>
  </footer>
</template>

<script>
import HomeM from './components/HomeM.vue'
import ServM from './components/ServM.vue'
import ContactM from './components/ContactM.vue'
import AboutM from './components/AboutM.vue'

export default {
  name: 'App',
  components: {
    HomeM,
    AboutM,
    ServM,
    ContactM
  },
  data() {
    return {
      color: false,
      currentPage: 'home',  // Default to the home page

    };

  },
  methods:{
    nav(){
      return{
        active:true
      };
    },

  },
}
</script>

<style>
.active,
.mail-hov:hover {
  color: #09B850;
}

.mail-hov {
  text-decoration: underline;
}

#header {
  margin-top: 20px;
}

#header img {
  width: 110px;
}

#header a:hover {
  color: #09B850;
}

#header li {
  list-style: none;
}

#blw-nav {
  margin-top: 200px;
}

.ser-con {
  margin: 10px;
}

/* .chng{
    height: 180px;
    margin-bottom: 20px; */
/* } */
#custom-swiper::-webkit-scrollbar {
  display: none;
  /* Hides scrollbar in WebKit browsers */
}

@media screen and (max-width: 819px) {
  #big-nav {
    display: none;
  }

}

@media screen and (max-width: 680px) {
  .compy {
    /* display: none; */
    flex-wrap: wrap;

  }

  #custom-swiper {
    display: none;
  }

}

@media screen and (max-width: 431px) {
  .sign {
    display: none;
  }

}
</style>
