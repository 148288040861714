<template>
    <div class="container">
        <div class="row">
            <div class="col-12 d-flex flex-column justify-content-center align-items-center"
                style="text-align: center;">
                <br><br><br>
                <p style="background-color: #F8F8F8;color: black;font-size: 1.3rem;" class="rounded p-2">🤩 What Drives
                    Us</p><br>
                <h1>We are an Independent Brand, Design, and Communication Agency</h1><br>
                <p style="font-size: 1.3rem;">We are an insight and behaviors driven creative marketing agency. A Full
                    package Digital Creative Agency Specializing in: Video Production, Web Design, Branding, Brand
                    Strategy, Content Marketing and Aerial Cinematography.</p>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row g-4 mt-5 mx-auto align-items-end">
            <div class="col-md-5 position-relative">

                <img src="https://mizzle.webestica.com/assets/images/about/14.jpg" class="rounded mb-4" alt="about-img">



                <div class="text-end position-relative d-none d-md-block">
                    <img src="https://mizzle.webestica.com/assets/images/about/16.jpg" class="rounded mb-4 w-50"
                        alt="about-img">
                </div>
            </div>

            <div class="col-md-7 position-relative mb-n4">
                <div class="card card-body bg-dark text-start z-index-2 w-75 p-5 mb-4">
                    <span class="text-white">Our Goal:</span>
                    <h4 class="text-white fw-light mb-0 mt-3">"We believe that it takes great people to deliver a great
                        product"</h4>
                </div>
                <div class="card card-body overflow-hidden p-0">
                    <img src="https://mizzle.webestica.com/assets/images/about/15.jpg" class="card-img" alt="about-img">
                    <div class="bg-overlay bg-dark opacity-2"></div>

                    <div class="position-absolute end-0 bottom-0 m-3 z-index-2">
                        <a href="https://www.youtube.com/embed/tXHviS-4ygo" class="btn btn-lh btn-white"
                            data-glightbox="" data-gallery="course-video"><i
                                class="bi bi-youtube text-danger fa-fw fa-xl me-1"></i> Watch our story</a>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <br><br><br><br><br><br>

    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Know About Mizzle</h1>
                <p style="font-size: 1.3rem;">At Mizzle, we're not just in the business of digital solutions; we're in
                    the business of crafting digital success stories. Founded on a passion for innovation and a
                    commitment to excellence, we've been at the forefront of the digital landscape, helping businesses
                    like yours thrive in the digital age.

                    Our story begins with a vision – a vision to transform the digital experience for businesses of all
                    sizes. Over the years, we've evolved from a small startup to a dynamic digital agency with a global
                    footprint.

                    To empower businesses to achieve their digital goals. We believe that the digital realm offers
                    boundless opportunities for growth and connection, and we're here to guide you through this
                    transformative journey.</p>
                <button type="button" class="btn btn-dark"><a href="/services.html"
                        style="color: white;font-size: 1.2rem;"> Exlpore Services!</a></button>
            </div>
        </div>
    </div><br><br>


</template>
<script>
export default {
    name: 'AboutM',

    props: {
        msg: String
    },


}
</script>