<template>

    <div class="container">
        <div class="row"><img src="../assets/media/02 (1).jpg" class="rounded" alt="">

        </div>
        <div class="row">
            <div class="col-12 d-flex justify-content-center align-items-center">

                <form action=""
                    style="display: block; margin:-100px 20px 0 20px; background-color:white;max-width: 700px;"
                    class="rounded shadow-sm p-3 mb-5 rounded">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 d-flex">Home <ul>
                                    <li style="color: #09B850;">Contact</li>
                                </ul>
                            </div>
                            <div class="col-12" style="font-size:2.5rem;font-weight: bolder;color: black;">
                                Let's level up your brand, together
                            </div>
                            <div class="col-12" style="margin: 10px 0;">
                                You can reach us anytime via <span
                                    style="color: #09B850;">abdullahshahidkamboh@gmail.com</span>
                                <br><br>
                                <hr>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-12" style="padding:10px 40px;">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" placeholder="Your name">
                                    <label for="floatingInput">Your Name</label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control" id="floatingInput"
                                        placeholder="name@example.com">
                                    <label for="floatingInput">Email address</label>
                                </div>
                                <div class="form-floating">
                                    <input type="tel" class="form-control" id="floatingPassword" placeholder="Phone">
                                    <label for="floatingPassword">Phone</label>
                                </div><br>
                                <div class="form-floating">
                                    <textarea class="form-control bg-transparent" placeholder="Leave a comment here"
                                        id="floatingTextarea2" style="height: 100px"></textarea>
                                    <label for="floatingTextarea2">Message</label>
                                </div><br>

                                <button type="button" class="btn btn-success"
                                    style="background-color: #09B850;font-size: 1.3rem;">Send a Message</button>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>

    </div>
    <div class="container-fluid">
        <div class="row d-flex justify-content-evenly align-items-center">
            <div class="col-xl-3 col-md-12 rounded shadow-sm p-3 mb-5 ">
                <div class="icon-con"><img src="../assets/media/loc.svg" alt="" srcset="" style="height: 30px;"></div>
                <br>
                <h5>Office Address</h5>
                <br>
                <div class="icon-con d-flex"><img src="../assets/media/us.svg" alt="" srcset=""
                        style="height: 30px;width:30px;border-radius: 50px;">
                    <h5 style="margin-left:  20px;">US office:</h5>
                </div>
                <p style="font-size: 1.2rem;">1421 Coburn Hollow Road Metamora, Near Center Point, IL 61548.</p>

            </div>
            <div class="col-xl-3 col-md-12 rounded shadow-sm p-3 mb-5 ">
                <div class="icon-con"><img src="../assets/media/mil.svg" alt="" srcset="" style="height: 30px;"></div>
                <br>
                <h5>Email us</h5>
                <br>
                <p style="font-size: 1.2rem;">We're on top of things and aim to respond to all inquiries within 24
                    hours.</p><br>
                <p class="mail-hov">abdullahshahidkamboh@gmail.com</p>

            </div>
            <div class="col-xl-3 col-md-12 rounded shadow-sm p-3 mb-5 ">
                <div class="icon-con"><img src="../assets/media/phy.svg" alt="" srcset="" style="height: 30px;"></div>
                <br>
                <h5>Call us</h5>
                <br>
                <p style="font-size: 1.2rem;">Let's work together towards a common goal - get in touch!</p><br>
                <p class="mail-hov">.(92) 3235604736</p>

            </div>

        </div>
    </div>
    <div class="container-fluid">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6802.122329137085!2d74.27673714999999!3d31.522479999999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919031009d4b8ad%3A0x6d484fcb40e8053a!2sBlock%20A%20Sabzazar%20Housing%20Scheme%20Phase%201%20%26%202%20Sabzazar%2C%20Lahore%2C%20Punjab!5e0!3m2!1sen!2s!4v1731864617138!5m2!1sen!2s"
            height="450" style="border:0;width: 100%;" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
</template>
<script>
export default {
    name: 'ContactM',

    props: {
        msg: String
    },


}
</script>
