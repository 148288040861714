<template>
    <!-- blow header 1st cont -->
    <div class="container" id="blw-nav">
        <div class="row">
            <div class="col-xl-6 row-md-12">
                <div class="container">
                    <div class="row ">
                        <div class="col-12 ">
                            <p class="py-2 px-3 rounded" style="background-color: #F8F8F8; width: 300px;">🤩
                                Award-winning digital services</p>
                        </div>
                        <div class="col-12 chng" style="font-size: 3rem;font-weight: bolder;">

                            Creative studio focused <br> on <span id="chg_name">UI/UX Design<span>|</span></span><span
                                style=" color: #09B850;">|</span></div>
                        <!-- chg ends  -->
                    </div>
                    <div class="col-12">From concept to execution, we deliver web designs that make an impact. Let your
                        brand shine with our innovative and visually stunning websites.</div>
                    <div class="col-12 d-flex justify-content-between align-items-center" style="padding-top: 20px;">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-6 col-md-12">
                                    <p class="d-flex align-items-center justify-content-center"><img
                                            src="../assets/media/bl-1.svg" style="margin-right: 10px;" alt=""><span
                                            class="d-flex flex-column"><span
                                                style="font-weight: bolder;font-size: 1.4rem;">24/7
                                                Support</span><span>We're always here to help</span></span></p>
                                </div>
                                <div class="col-xl-6 col-md-12">
                                    <p class="d-flex align-items-center justify-content-center"><img src="../assets/media/bl2.svg"
                                            style="margin-right: 10px;" alt=""><span class="d-flex flex-column"><span
                                                style="font-weight: bolder;font-size: 1.4rem;">Award Winning
                                                agency</span><span>You are in safe hand</span></span></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                </div>
            </div>

            <div class="col-xl-6 col-md-12 d-flex align-items-center justify-content-center">
                <div class="2nd-bl"><img src="../assets/media/01.jpg" style="height: 600px;"></div>
            </div>
        </div>

    </div>
    <br><br><br>
    <section class="pb-0">
        <div class="container">
            <div class="row align-items-center d-flex">
                <div class="col-md-3 mb-2 mb-md-0 ">
                    <h5 class="pe-6">100+ Companies trust us</h5>
                </div>

                <div class="col-md-9">
                    <!-- Slider START -->
                    <div class="swiper swiper-initialized swiper-horizontal swiper-backface-hidden">

                        <!-- Slider items -->
                        <div class="swiper-wrapper align-items-center d-flex " id="custom-swiper"
                            style="overflow-x: auto;transition-duration: 0ms; transform: translate3d(-175px, 0px, 0px); transition-delay: 0ms;"
                            aria-live="off">


                            <div class="swiper-slide swiper-slide-prev " role="group" aria-label="3 / 6"
                                style=" margin-right: 50px;" data-swiper-slide-index="2">
                                <img src="../assets/media/01.svg" width="150px" class="grayscale" alt="client-img">
                            </div>
                            <div class="swiper-slide swiper-slide-active" role="group" aria-label="4 / 6"
                                style="width: 125px; margin-right: 50px;" data-swiper-slide-index="3">
                                <img src="../assets/media/02.svg" width="150px" class="grayscale" alt="client-img">
                            </div>
                            <div class="swiper-slide swiper-slide-next" role="group" aria-label="5 / 6"
                                style="width: 125px; margin-right: 50px;" data-swiper-slide-index="4">
                                <img src="../assets/media/03.svg" width="150px" class="grayscale" alt="client-img">
                            </div>
                            <div class="swiper-slide" role="group" aria-label="6 / 6"
                                style="width: 125px; margin-right: 50px;" data-swiper-slide-index="5">
                                <img src="../assets/media/04.svg" width="150px" class="grayscale" alt="client-img">
                            </div>
                            <div class="swiper-slide" role="group" aria-label="1 / 6"
                                style="width: 125px; margin-right: 50px;" data-swiper-slide-index="0">
                                <img src="../assets/media/01 (1).svg" width="150px" class="grayscale" alt="client-img">
                            </div>
                            <div class="swiper-slide" role="group" aria-label="2 / 6"
                                style="width: 125px; margin-right: 50px;" data-swiper-slide-index="1">
                                <img src="../assets/media/05.svg" width="150px" class="grayscale" alt="client-img">
                            </div>
                        </div>
                        <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                    </div>
                    <!-- Slider END -->
                </div>
            </div> <!-- Row END -->
        </div>
    </section><br><br><br>


    <section>
        <div class="container">
            <div class="row g-4 align-items-xl-center">

                <!-- About image START -->
                <div class="col-lg-7">
                    <div class="row pe-xl-5">
                        <div class="col-sm-6">
                            <!-- About image -->
                            <img src="../assets/media/01 (1).jpg" class="rounded" alt="about-img">



                        </div>

                        <div class="col-sm-6">
                            <!-- Skill set -->
                            <div class="row mb-4">
                                <div class="col-sm-6 mb-4 mb-sm-0">
                                    <div class="bg-dark text-white rounded text-center p-3">
                                        <span class="h2 text-white">10+</span>
                                        <p class="mb-0">Years of experience</p>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="bg-primary rounded text-center p-3">
                                        <span class="h2 text-white">1.2K</span>
                                        <p class="mb-0 text-white">Happy customers</p>
                                    </div>
                                </div>
                            </div>

                            <!-- About image -->
                            <img src="../assets/media/02.jpg" class="rounded" alt="about-img-2">
                        </div>
                    </div> <!-- Row END -->
                </div>
                <!-- About image END -->

                <!-- About content START -->
                <div class="col-lg-5">
                    <span class="heading-color bg-light small rounded-3 px-3 py-2">🤝 Your gateway to digital
                        success</span>
                    <h2 class="my-4">Defining the future of online experiences!</h2>
                    <p class="mb-4">Embrace a new era of digital success with Mizzle. Our team combines cutting-edge
                        design with robust development to deliver websites that captivate and convert.</p>
                    <!-- List -->
                    <ul class="list-group list-group-borderless mb-4">
                        <li class="list-group-item heading-color d-flex mb-0"><i
                                class="bi bi-patch-check-fill text-primary me-2"></i>Emphasis on ROI-driven solutions
                        </li>
                        <li class="list-group-item heading-color d-flex mb-0"><i
                                class="bi bi-patch-check-fill text-primary me-2"></i>Expert team with diverse skill</li>
                        <li class="list-group-item heading-color d-flex mb-0"><i
                                class="bi bi-patch-check-fill text-primary me-2"></i>Proven track record of delivering
                            results</li>
                    </ul>
                    <!-- Button -->
                    <a href="#" class="btn btn-dark mb-0">Discover more</a>
                </div>
                <!-- About content END -->

            </div>
        </div>
    </section>



    <div class="container-fluid position-relative" id="services"
        style="background-color: black; color: white;padding-bottom: 100px;">
        <!-- Title -->
        <div class="inner-container-small text-center">
            <span class="bg-light heading-color small rounded-3 px-3 py-2">🚀 Innovative solutions, Measurable
                results</span>
            <h2 class="mb-0 mt-4" style="color: white;">We specialize in the following services</h2>
        </div>

        <!-- Service list START -->
        <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 gy-5 gy-md-7 mt-3">
            <!-- Service item -->
            <div class="col">
                <div class="card bg-light h-100">
                    <div class="card-body pb-0">
                        <!-- Icon -->
                        <div class="icon-lg bg-white text-primary rounded-circle mb-4 mt-n5">
                            <i class="bi bi-stack fa-fw fs-5"></i>
                        </div>
                        <!-- Content -->
                        <h5 class="mb-3"><a href="#">Digital Marketing</a></h5>
                        <p>Growing brands online through digital channels.</p>
                    </div>
                    <div class="card-footer bg-light mt-auto pt-2">
                        <a class="icon-link icon-link-hover" href="#">Know more<i class="bi bi-arrow-right"></i> </a>
                    </div>
                </div>
            </div>

            <!-- Service item -->
            <div class="col">
                <div class="card bg-light h-100">
                    <div class="card-body pb-0">
                        <!-- Icon -->
                        <div class="icon-lg bg-white text-primary rounded-circle mb-4 mt-n5">
                            <i class="bi bi-boxes fa-fw fs-5"></i>
                        </div>
                        <!-- Content -->
                        <h5 class="mb-3"><a href="#">Product Design</a></h5>
                        <p>Creating products that users love and businesses need .</p>
                    </div>
                    <div class="card-footer bg-light mt-auto pt-2">
                        <a class="icon-link icon-link-hover" href="#">Know more<i class="bi bi-arrow-right"></i> </a>
                    </div>
                </div>
            </div>

            <!-- Service item -->
            <div class="col">
                <div class="card bg-light h-100">
                    <div class="card-body pb-0">
                        <!-- Icon -->
                        <div class="icon-lg bg-white text-primary rounded-circle mb-4 mt-n5">
                            <i class="bi bi-circle-square fa-fw fs-5"></i>
                        </div>
                        <!-- Content -->
                        <h5 class="mb-3"><a href="#">Web Design</a></h5>
                        <p>Designing websites that are visually appealing &amp; user-friendly.</p>
                    </div>
                    <div class="card-footer bg-light mt-auto pt-2">
                        <a class="icon-link icon-link-hover" href="#">Know more<i class="bi bi-arrow-right"></i> </a>
                    </div>
                </div>
            </div>

            <!-- Service item -->
            <div class="col">
                <div class="card bg-light h-100">
                    <div class="card-body pb-0">
                        <!-- Icon -->
                        <div class="icon-lg bg-white text-primary rounded-circle mb-4 mt-n5">
                            <i class="bi bi-vector-pen fa-fw fs-5"></i>
                        </div>
                        <!-- Content -->
                        <h5 class="mb-3"><a href="#">UI/UX Design</a></h5>
                        <p>Creating user interfaces that are both efficient and enjoyable.</p>
                    </div>
                    <div class="card-footer bg-light mt-auto pt-2">
                        <a class="icon-link icon-link-hover" href="#">Know more<i class="bi bi-arrow-right"></i> </a>
                    </div>
                </div>
            </div>

            <!-- Service item -->
            <div class="col">
                <div class="card bg-light h-100">
                    <div class="card-body pb-0">
                        <!-- Icon -->
                        <div class="icon-lg bg-white text-primary rounded-circle mb-4 mt-n5">
                            <i class="bi bi-briefcase fa-fw fs-5"></i>
                        </div>
                        <!-- Content -->
                        <h5 class="mb-3"><a href="#">Business Strategy</a></h5>
                        <p>Developing and executing plans to achieve business goals.</p>
                    </div>
                    <div class="card-footer bg-light mt-auto pt-2">
                        <a class="icon-link icon-link-hover" href="#">Know more<i class="bi bi-arrow-right"></i> </a>
                    </div>
                </div>
            </div>

            <!-- Service item -->
            <div class="col">
                <div class="card bg-light h-100">
                    <div class="card-body pb-0">
                        <!-- Icon -->
                        <div class="icon-lg bg-white text-primary rounded-circle mb-4 mt-n5">
                            <i class="bi bi-binoculars fa-fw fs-5"></i>
                        </div>
                        <!-- Content -->
                        <h5 class="mb-3"><a href="#">Research</a></h5>
                        <p>Gathering and analyzing data to inform decision-making.</p>
                    </div>
                    <div class="card-footer bg-light mt-auto pt-2">
                        <a class="icon-link icon-link-hover" href="#">Know more<i class="bi bi-arrow-right"></i> </a>
                    </div>
                </div>
            </div>

            <!-- Service item -->
            <div class="col">
                <div class="card bg-light h-100">
                    <div class="card-body pb-0">
                        <!-- Icon -->
                        <div class="icon-lg bg-white text-primary rounded-circle mb-4 mt-n5"><i
                                class="bi bi-code-slash fa-fw fs-5"></i></div>
                        <!-- Content -->
                        <h5 class="mb-3"><a href="#">Web Development</a></h5>
                        <p>Building and maintaining websites and web applications.</p>
                    </div>
                    <div class="card-footer bg-light mt-auto pt-2">
                        <a class="icon-link icon-link-hover" href="#">Know more<i class="bi bi-arrow-right"></i> </a>
                    </div>
                </div>
            </div>

            <!-- Service item -->
            <div class="col">
                <div class="card bg-light h-100">
                    <div class="card-body pb-0">
                        <!-- Icon -->
                        <div class="icon-lg bg-white text-primary rounded-circle mb-4 mt-n5">
                            <i class="bi bi-bullseye fa-fw fs-5"></i>
                        </div>
                        <!-- Content -->
                        <h5 class="mb-3"><a href="#">Brand Design</a></h5>
                        <p>Creating brands that are unique, memorable, and meaningful.</p>
                    </div>
                    <div class="card-footer bg-light mt-auto pt-2">
                        <a class="icon-link icon-link-hover" href="#">Know more<i class="bi bi-arrow-right"></i> </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Service list END -->
    </div>

    <br><br><br>



    <section class="position-relative z-index-2 py-0 mb-n7">
        <div class="container position-relative">
            <div class="bg-primary rounded position-relative overflow-hidden p-4 p-sm-5">


                <div class="row g-4 position-relative">
                    <!-- Title and inputs -->
                    <div class="col-lg-6">
                        <!-- Title -->
                        <h3 class="text-white">Let's talk about your digital agency goals</h3>

                        <!-- Search -->
                        <form class="col-md-10 bg-body rounded-2 p-2 mt-4">
                            <div class="input-group">
                                <input class="form-control focus-shadow-none border-0 me-1" type="email"
                                    placeholder="Your email address">
                                <button type="button" class="btn btn-dark rounded-2">Subscribe</button>
                            </div>
                        </form>
                    </div>

                    <!-- Content -->
                    <div class="col-lg-5 col-xl-4 ms-auto text-lg-end">
                        <ul class="list-group list-group-borderless mb-0">
                            <li class="list-group-item mb-0">
                                <a href="#" class="text-white fw-normal">
                                    <i class="bi bi-headset me-1"></i> Call on: 0125 258 993 22
                                </a>
                            </li>
                            <li class="list-group-item text-white fw-normal mb-0">
                                <i class="bi bi-clock me-1"></i> Time: 9am to 5pm (Sunday close)
                            </li>
                            <li class="list-group-item mb-0">
                                <a href="#" class="text-white fw-normal">
                                    <i class="bi bi-envelope me-1"></i> Email: example@gmail.com
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> <!-- Row END -->
            </div>
        </div>
    </section>
    
</template>

<script>
export default {
    
    name: 'HomeM',

    props: {
        msg: String
    },


}
</script>
